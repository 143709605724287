import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import LWFiles from 'components/data/Files';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { findAssetInStore } from 'components/asset-management/utilities';
import { MediaAsset } from '../types/media-management.type';

const downloadMediaAssets = async (itemIds: string[], onSetWaiting: (waiting: boolean) => void): Promise<void> => {
    onSetWaiting(true);
    SnackbarUtils.info(Translation.get('snackbar.preparingDownload', 'media-management'));

    if (itemIds.length === 1) {
        const foundAsset = findAssetInStore(itemIds[0], 'MediaManagement');
        if (foundAsset) {
            const asset = foundAsset as MediaAsset;
            if (asset.data.files?.length === 1) {
                const fileUrl = asset.data.files[0]?.url;
                const fileName = asset.data.files[0].fileName;

                if (fileUrl && fileName) {
                    SnackbarUtils.success(Translation.get('snackbar.downloadStared', 'media-management'));
                    LWFiles.downloadFileFromUrl(fileUrl, fileName, () => onSetWaiting(false));
                    return;
                } else {
                    SnackbarUtils.error(Translation.get('snackbar.downloadFailed', 'media-management'));
                    onSetWaiting(false);
                    return;
                }
            }
        }
    }

    const { url } = await AssetManagementService.assetDownload(itemIds);

    if (url) {
        SnackbarUtils.success(Translation.get('snackbar.downloadStared', 'media-management'));
        LWFiles.downloadFileFromUrl(url, 'asset-selection.zip', () => onSetWaiting(false));
        return;
    }
};

export { downloadMediaAssets };
